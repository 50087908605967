import { OrgRole, isOrgRoleSuperset } from "@hex/common";

import { useCurrentUser } from "./useCurrentUser.js";

/**
 * Returns true if the current user is an admin.
 */
export const useCurrentUserIsAdmin = (): boolean => {
  const currentUser = useCurrentUser();
  if (!currentUser) return false;
  return isOrgRoleSuperset(currentUser.orgRole, OrgRole.ADMIN);
};
